
export default defineNuxtRouteMiddleware(async (to) => {
  const $auth = useAuth();
  const currentPrefix = $auth.strategy.token.scheme.options.token.prefix;
  const workspaceSlug = to.params.tenantSlug || '';
  const newPrefix = `${workspaceSlug}_token.`;

  if (currentPrefix === newPrefix) return;

  $auth.strategy.token.scheme.options.token.prefix = newPrefix;

  if ($auth.user?.workspace?.slug === workspaceSlug) {
    await $auth.fetchUserOnce();
  } else {
    await $auth.fetchUser();
  }
});
